import React from 'react'
import {Heading} from '@chakra-ui/react'
import {animated, config, useSpring} from 'react-spring'
import MySEO from '../components/seo'

import {Text} from '@chakra-ui/react'

const Kontakt = () => {
    const props = useSpring({
        from:{color:'white',fontSize:"1rem"},

        to: {color:'black',fontSize:"1.5rem"},
        config:config.wobbly
    })
    return(
        <animated.div style={props}>
      <MySEO
        title="Kontakt"
        description="Kontaktdaten von Hasen oder Bein"
      />

            <Heading># kontakt | <span style={{color:"grey", fontWeight:200}}>contact</span></Heading>
        <Text>

            Katrin Gloggengießer | Dipl. Kommunikationsdesignerin(FH)<br/>
            mobil: 0151.70842472<br/>
            mail: kontakt@hasenoderbein.de<br/>

        </Text>


        </animated.div>
    )}
    export default Kontakt
